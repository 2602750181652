<template>
  <header>
    <div class="inner">
      <router-link to="/" class="logo"></router-link>
      <h1><router-link to="/">GammaTeam Security</router-link></h1>
    </div>
    <div class="parallax hero"></div>
  </header>
</template>

<script>
export default {
  name: 'gammaHeader',
  data: () => ({
    width: 0
  }),
  computed: {
    innerWidth: function () {
      return window.innerWidth
    }
  }
}
</script>

<style scoped>
/* Header */
header {
  width: 100%;
  padding: 10px 0 0 10px;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
}
header .inner {
  border-radius: 10px;
  color: rgba(255,255,255,1);
  overflow: auto;
  padding: 0;
}
header .hero {
  background-image: url(/images/splash5.jpg);
  background-position: center 0;
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
}
header .inner .logo {
  background-image: url(/images/logo.png);
  background-size: 80px 80px;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 10px;
  left: 20px;
  z-index: 10;
}
header .inner h1 {
  text-transform: uppercase;
  position: fixed;
  top: 25px;
  left: 110px;
  z-index: 10;
  text-shadow: 2px 2px 10px #000;
}
header .inner h1 a {
  color: inherit;
  text-decoration: none;
}
header .inner h1 a:hover {
  text-shadow: 2px 2px 10px rgba(30,144,255,.75);
}

/* media queries */
@media screen and (max-width: 1400px) {
  header .inner .logo {
    background-size: 70px 70px;
    width: 70px;
    height: 70px;
  }
  header .inner h1 {
    font-size: 1.7em;
  }
}
@media screen and (max-width: 1280px) {
  header .inner h1 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 1160px) {
  header .inner .logo {
    background-size: 60px 60px;
    width: 60px;
    height: 60px;
  }
  header .inner h1 {
    font-size: 1.4em;
    letter-spacing: 3px;
    left: 90px
  }
}
@media screen and (max-width: 1028px) {
  header .inner h1 {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 920px) {
}
@media screen and (max-width: 770px) {
  header .hero {
    background-image: url(/images/splash5_780.png);
  }
}
@media screen and (max-width: 650px) {
  header .inner h1 {
    font-size: 2em;
    text-shadow: 2px 2px 10px #000;
  }
  header .hero {
    background-image: url(/images/splash5_780.png);
    background-position: center center;
  }
}
@media screen and (max-width: 610px) {
  header .hero {
    background-image: none;
    background-color: rgba(0, 0, 0, .75);
    height: 80px;
  }
  header .inner .logo {
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    left: 10px;
    top: 20px;
  }
  header .inner h1 {
    font-size: 1.5em;
    top: 25px;
    left: 70px;
  }
}
@media screen and (max-width: 450px) {
  header .inner .logo {
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
  }
  header .inner h1 {
    font-size: 1.3em;
    top: 28px;
    left: 65px;
  }
}
@media screen and (max-width: 380px) {
  header .inner h1 {
    letter-spacing: normal;
  }
}
</style>
