<template>
  <div class="print">
    <template v-if="Object.keys(applications).length > 0 && idx >= 0">
      <div id="application" width="90%">
        <div class="title">
          <h3>{{ (applications[idx].position.position_title ? applications[idx].position.position_title : "Position Title Not Specified") }}</h3>
        </div>
        <applicationView :application="applications[idx]" :showAll="true"></applicationView>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import applicationView from '@/components/applicationView'
export default {
  name: 'Dashboard',
  data: () => ({
    idx: -1,
    applications: []
  }),
  components: { applicationView },
  methods: {
    delete_application: function (item) {
      if (window.confirm('Are you sure you want to delete this application?')) {
        this.requestHandler.doDelete('application', '/' + item.id)
          .then(response => {
            console.log(response)
            if (response.data.success) {
              const idx = this.applications.findIndex((app) => app.id === item.id)
              if (idx > -1) this.applications.splice(idx, 1)
            }
          })
      }
    },
    view_application: function (i) {
      this.setOverlayStatus(true, false, false)
      console.log(i)
      this.idx = i
    },
    close_view: function () {
      this.$store.commit('setOverlayStatus', {
        show: false,
        allow_dismissal: true,
        expend_menu: false
      })
      this.idx = -1
    }
  },
  computed: {
  },
  filters: {
    friendly_date: function (value) {
      return moment(value).format('MM/DD/YYYY')
    },
    is_complete: function (value) {
      return (value === 10 ? 'yes' : 'no')
    }
  },
  created () {
    this.requestHandler.doGet('application', '/all')
      .then(response => {
        this.applications = response.data.data
        console.log(this.applications)
        this.idx = this.$route.params.id
        window.print()
      })
  }
}
</script>

<style scoped>
#application {
  width: 100%;
  height: auto;
}
#application div.title {
  width: 100%;
  height: auto;
  padding: 15px;
  background-color: #ddd;
  font-size: 2em;
}
#application div.contents {
  background-color: white;
  width: 100%;
  color: black;
  margin: 0 0 0 0;
  border-radius: 10px;
  padding: 15px;
}
.print {
  clear: both;
  display: block;
}
</style>
<style>
.mylivechat_template1 {
  display: none;
}
</style>
