<template>
    <div>
      <div class="testimonials">
        <h3>Meet our happy customers</h3>
        <div class="testimonial">
          <div class="prev">
            <v-btn
                icon
                x-large
                class="text--black"
                @click.stop="prev"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <div :class="'content' + transitionClass">
            <h3>{{ testimonials[index].name }}</h3>
            <h5>{{ testimonials[index].title }}</h5>
            <p>{{ testimonials[index].text }}</p>
          </div>
          <div class="next">
            <v-btn
                icon
                x-large
                @click.stop="next"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'testimonials',
  data: () => ({
    index: 0,
    transition: '',
    testimonials: [
      {
        name: 'Mark Kraft',
        title: 'Director at Clyde Park District',
        text: 'It is a great pleasure for Clyde Park District to work in conjunction with GTS professional team'
      },
      {
        name: 'Dmitrius Bousis',
        title: 'President at Cermak Product, Inc.',
        text: 'GTS is providing customers with high-quality service and qualified officers with high level skills. I would recommend this Company for future business relationships, exchanging security experience and new technologies.'
      },
      {
        name: 'Raymond Alm',
        title: 'Manager, Facilities & Campus Optimization at Sysmex America, Inc',
        text: 'Thank you, GTS team for all the excellent program safety support for Sysmex!'
      },
      {
        name: 'Rodolfo Hernandez',
        title: 'Superintendent at Cicero School District 99',
        text: 'GTS is committed to provide the best service possible, and implement the most updated procedures and techniques to keep our schools safe.'
      },
      {
        name: 'Michael Zwolinski',
        title: 'Acting General Manager at Delta Hotels by Marriot Chicago Northshore Suites',
        text: 'Our managers are very happy with the quality of work provided by GTS security guards.  They are professional, friendly and active throughout the hotel.  This is exactly what we were looking for.'
      },
      {
        name: 'Sara Hester',
        title: 'Account Representative at Shekar & Oinos.',
        text: "I have heard nothing but good things from our venue staff about your services. We are very happy we found you and that you have been able to assist us in helping make someone's special day less stressful and a more safe environment."
      },
      {
        name: 'John Rodriguez',
        title: 'Store Manager at Fallas Stores, Inc.',
        text: 'Their security service is great! It is always efficient and respectable.'
      },
      {
        name: 'Marcel Olshansky',
        title: 'President at Liquor Barn Stores.',
        text: 'A reliable and easy to work with security company!'
      }
    ]
  }),
  timeoutMain: null,
  timeoutDisplay: null,
  methods: {
    prev: function () {
      if (this.index - 1 <= 0) this.index = this.testimonials.length - 1
      else this.index--
    },
    next: function () {
      if (this.index >= this.testimonials.length - 1) this.index = 0
      else this.index++
    },
    display: function (callback) {
      this.transition = ' fade-out-2'
      setTimeout(() => {
        this.transition = ' fade-in-2'
        callback()
      }, 2000)
    },
    startTimer: function () {
      clearInterval(window.timeoutMain)
      window.timeoutMain = setInterval(() => {
        this.display(() => {
          this.next()
        })
      }, 10000)
    }
  },
  computed: {
    transitionClass: function () {
      return this.transition
    }
  },
  created () {
    this.startTimer()
  }
}
</script>

<style scoped>
div.testimonials {
  width: 100%;
  margin: 40px 0 60px 0;
}
div.testimonials::after {
  content: '';
  clear: both;
  display: block;
}
div.testimonials > h3 {
 text-align: center;
 font-size: 2em;
 color: #fff;
}
div.testimonial {
  float: left;
  background: rgba(0, 0, 0, .5);
  /*background: linear-gradient(180deg, rgba(0,0,0,1) 0%, transparent 100%);*/
  border-radius: 15px;
  box-shadow: 5px 5px 15px black;
  padding: 25px 50px;
  text-align: center;
  margin: 20px auto 0 auto;
  display: block;
  overflow: hidden;
  width: 100%;
}
div.prev {
  float: left;
  width: 5%;
  margin: 20px 0 0 0;
}
div.next {
  float: right;
  width: 5%;
  margin: 20px 0 0 0;
}
div.content {
  float: left;
  width: 90%;
}
div.testimonial h3 {
  color: #2a7afa;
  font-size: 2em;
  text-transform: capitalize;
}
div.testimonial h5 {
  letter-spacing: 2px;
  font-weight: 500;
  color: white;
  font-size: 1em;
  text-transform: capitalize;
}
div.testimonial p {
  margin-top: 30px;
  line-height: 1.4em;
  letter-spacing: 1px;
  font-style: italic;
  color: white;
  font-weight: 400;
  padding: 10px 0;
  font-size: 1.3em;
  width: 100%;
}
div.testimonial p::before {
  content: '"'
}
div.testimonial p::before {
  content: '"'
}
div.testimonial p::after {
  content: '"';
}
/* media queries */
@media screen and (max-width: 650px) {
  div.testimonials > h3 {
    font-size: 1.2em;
  }
  div.testimonials div.testimonial {
    padding: 15px 25px;
    width: 100%;
    position: relative;
  }
  div.testimonials div.testimonial div.prev {
    float: none;
    position: absolute;
    left: 40%;
    top: 0;
  }
  div.testimonials div.testimonial div.next {
    float: none;
    position: absolute;
    right: 40%;
    top: 0;
  }
  div.testimonials div.testimonial div.content {
    width: 100%;
    display: block;
    margin-top: 40px;
  }
  .v-btn--icon.v-size--x-large {
    height: 30px;
    width: 30px;
  }
}
@media screen and (max-width: 380px) {
  div.testimonials div.testimonial h3 {
    font-size: 1.5em;
  }
  div.testimonials div.testimonial h5 {
    font-size: .9em;
  }
  div.testimonials div.testimonial div.content p {
    margin-top: 10px;
    font-size: 1.1em;
  }
}
</style>
