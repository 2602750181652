import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/router'
import store from '@/store'
import '@/plugins/axios'
import $ from 'jquery'
import RequestHandler from '@/service/requestHandler'
import callToAction from '@/components/call-to-action'
Vue.component('call-to-action', callToAction)

Vue.config.productionTip = false

export const EventBus = new Vue()

Vue.mixin({
  data: () => ({
    requestHandler: new RequestHandler()
  }),
  methods: {
    goToPage: function (routeName) {
      let found = false
      if (this.$router.apps[0]._route.name !== routeName) {
        this.$router.options.routes.forEach(route => {
          if (!found) {
            if (route.name === routeName) {
              router.push({ name: routeName })
              found = true
            } else if (route.children) {
              route.children.forEach(childRoute => {
                if (!found) {
                  if (childRoute.name === routeName) {
                    router.push({ name: routeName })
                    found = true
                  }
                }
              })
            }
          }
        })
        if (!found) {
          router.push({ name: 'PageNotFound' })
        }
      }
    },
    getUrlFromName: function (name) {
      return name.toLowerCase().replaceAll(' & ', '-').replaceAll(' ', '-')
    },
    setOverlayStatus: function (show, allowDismissal, expandMenu) {
      this.$store.commit('setOverlayStatus', {
        show: show,
        allowDismissal: allowDismissal,
        expandMenu: expandMenu
      })
    },
    field_check: function (field) {
      if (field.prop('required') && !field.val()) {
        field.addClass('field_error')
        return false
      } else if (field.is('select') && field.val() === '-1') {
        field.addClass('field_error')
        return false
      } else field.removeClass('field_error')
      return true
    }
  },
  computed: {
    currentRouteName: function () {
      return this.$router.apps[0]._route.name
    }
  }
})

new Vue({
  vuetify,
  router,
  store,
  $,
  render: h => h(App)
}).$mount('#app')
