import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '@/router'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    auth: {
      isAuthenticated: false,
      accessToken: null,
      permissions: [],
      user: {}
    },
    show_header: true,
    overlay: {
      show: false,
      allow_dismissal: true,
      expandMenu: false
    },
    application_id: -1,
    prevent_scrolling: false,
    contact: {
      IL: {
        title: 'Illinois Corporate Office',
        phone: '224-235-4347',
        fax: '847-346-0677',
        email: 'info@gammateamcorp.com',
        address1: '3520 Commercial Ave',
        city: 'Northbrook',
        state: 'IL',
        zip: '60062'
      },
      WI: {
        title: 'Wisconsin Local Office',
        phone: '608-234-4009',
        fax: '847-346-0677',
        email: 'info@gammateamcorp.com',
        address1: '4211 N. lightning Dr, Suite 333',
        city: 'Appleton',
        state: 'WI',
        zip: '54913'
      }
    },
    services: ['Healthcare Security', 'Hospitality Security', 'Commercial Security', 'Education Security', 'Construction Site Security', 'Security Consulting', 'Special Event Security'],
    states: ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
  },
  plugins: [
    createPersistedState({
      key: 'vuex',
      reducer: persistedState => {
        const stateFilter = Object.assign({}, persistedState)
        const whitelist = ['auth', 'application_id']

        // clear state except whitelisted items (i.e. auth)
        Object.keys(stateFilter).forEach(item => {
          if (!whitelist.includes(item)) {
            delete stateFilter[item]
          }
        })

        return stateFilter
      }
    })
  ],
  mutations: {
    authenticateUser (state, data) {
      state.auth.isAuthenticated = true
      // state.auth.permissions = data.permissions;
      state.auth.user = data.user
      state.auth.accessToken = data.access_token

      localStorage.setItem('at', data.access_token)

      // this.commit("resetInactivityTimer");

      router.push({ name: 'Dashboard' })
    },
    logoutUser (state) {
      state.auth = {
        isAuthenticated: false,
        accessToken: null,
        permissions: [],
        user: {}
      }
      localStorage.removeItem('at')

      router.push({ name: 'Login' })
    },
    setOverlayStatus (state, data) {
      state.overlay.show = data.show
      state.overlay.allow_dismissal = data.allow_dismissal
      state.overlay.expandMenu = data.expandMenu
    },
    updateApplicationId (state, data) {
      state.application_id = data.application_id
    }
  },
  actions: {
    authenticateUser ({ commit }, data) {
      commit('authenticateUser', data)
    },
    setOverlayStatus ({ commit }, data) {
      commit('setOverlayStatus', data)
    },
    updateApplicationId ({ commit }, data) {
      commit('updateApplicationId', data)
    },
    logoutUser ({ commit }) {
      commit('logoutUser')
    }
  },
  getters: {
    isAuthenticated: state => state.auth.isAuthenticated,
    accessToken: state => state.auth.accessToken,
    user: state => state.auth.user,
    overlay: state => state.overlay
  }
})
export default store
