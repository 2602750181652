/* eslint-disable no-unexpected-multiline */
/* eslint-disable no-debugger */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/pages/Home.vue'
import PageNotFound from '@/views/pages/PageNotFound.vue'
import DefaultLayout from '@/views/DefaultLayout'
import AdminLayout from '@/views/AdminLayout'
import Dashboard from '@/views/pages/Dashboard'
import PrintApplication from '@/views/pages/Print_Application'
import OnBoarding from '@/views/pages/OnBoarding'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const _routes = [
  {
    path: '',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false
    }
  }
]
const _getUrlFromName = function (name) {
  return name.toLowerCase().replaceAll(' ', '-')
}
store.state.services.forEach((item) => {
  try {
    _routes.push({
      path: '/services/' + _getUrlFromName(item),
      name: item,
      component: () => import('@/views/pages/' + item + '.vue'),
      meta: {
        requiresAuth: false
      }
    })
  } catch (e) {
    console.error(e)
  }
})

let pages = ['About', 'Services', 'Contact Us', 'Careers', 'Login']
pages.forEach((item) => {
  _routes.push({
    path: '/' + _getUrlFromName(item),
    name: item,
    component: () => import('@/views/pages/' + item + '.vue'),
    meta: {
      requiresAuth: false
    }
  })
})

pages = ['Mission', 'Testimonials']
pages.forEach((item) => {
  _routes.push({
    path: '/about/' + _getUrlFromName(item),
    name: item,
    component: () => import('@/views/pages/' + item + '.vue'),
    meta: {
      requiresAuth: false
    }
  })
})

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: _routes
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/admin/print/application/:id',
    name: 'PrintApplication',
    component: PrintApplication,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/onboarding/:code',
    name: 'OnBoarding',
    component: OnBoarding
  },
  {
    path: '/*',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
          requiresAuth: false
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const stateInstance = store.state
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const isAuth = stateInstance.auth.isAuthenticated && stateInstance.auth.accessToken != null
    if (!isAuth) {
      next({
        path: '/login'
      })
    } else next()
    // else {
    //   if (to.matched.some(record => record.meta.requiresAdmin)) {
    //     // check if logged in user has the necessary permission to view this page
    //     if (to.meta.requiresAdmin && stateInstance.auth.user.role_id === 2) next()
    //     else {
    //       next({
    //         name: 'Unauthorized'
    //       })
    //     }
    //   } else next() // make sure to always call next()!
    // }
  } else next() // make sure to always call next()!
})

export default router
