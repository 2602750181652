<template>
  <div class="call-to-action">
    <template v-if="link_back">
      Call us today to discuss how GammaTeam Security can help you with <router-link to="/">{{ currentRouteName }}</router-link>! <a :href="'tel:' + $store.state.contact.IL.phone">{{  $store.state.contact.IL.phone }}</a>
    </template>
    <template v-else>
      Call us today to discuss how GammaTeam Security can help you with all your <router-link :to="link_to">security</router-link> needs! <a :href="'tel:' + $store.state.contact.IL.phone">{{  $store.state.contact.IL.phone }}</a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'call-to-action',
  props: {
    link_back: Boolean
  },
  computed: {
    link_to: function () {
      if (!this.link_back) {
        if (this.$router.apps[0]._route.name === 'Home') return '/services'
        else if (this.$router.apps[0]._route.name === 'Services') return '/'
      }
      return '/'
    }
  }
}
</script>

<style scoped>
/* styles */
section.main div.call-to-action {
  display: block;
  clear: both;
  width: 80%;
  margin: 20px auto 0 auto;
  font-size: 1.2em;
  background-color: rgba(0,0,0,.25);
  padding: 15px 40px;
  border-radius: 10px;
  border: solid 1px rgba(255,255,255,.25);
  text-align: center;
}
section.main div.call-to-action a[href^="tel"] {
  display: block;
  width: 200px;
  margin: 15px auto 0 auto;
  background-color: #2a7afa;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  box-shadow: 4px 4px 10px #222;
}
section.main div.call-to-action a[href^="tel"]:hover {
  background-color: rgba(255,255,255,.75);
  color: #000;
}

/* media queries */
@media screen and (max-width: 1280px) {
  section.main div.call-to-action {
    width: 90%;
  }
}
@media screen and (max-width: 1028px) {
  section.main div.call-to-action {
    width: 100%;
  }
}
@media screen and (max-width: 920px) {
  section.main div.call-to-action {
    width: 90%;
    display: block;
    text-align: center;
  }
  section.main div.call-to-action a[href^="tel"] {
    display: block;
    width: 200px;
    margin: 15px auto 0 auto;
  }
}
@media screen and (max-width: 650px) {
  section.main div.call-to-action {
    width: 80%;
    padding: 15px 20px;
  }
}
@media screen and (max-width: 450px) {
  section.main div.call-to-action {
    width: 90%;
  }
}
</style>
