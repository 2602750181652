<template>
  <div class="contents">
    <v-expansion-panels
      accordion
      multiple
      :value="active_section"
    >
      <v-expansion-panel v-for="(section, i) in sections_computed" :key=i>
        <v-expansion-panel-header class="application_section_header">{{ section.label }}</v-expansion-panel-header>
        <v-expansion-panel-content :class="`application_section_content ` + section.key">
          <template v-if="section.key === 'applicant'">
            <article>
              <span class="heading">Name: </span>
              <span>{{ application.applicant.first }}</span>
              <span>{{ application.applicant.last }}</span>
              <span>{{ application.applicant.middle }}</span>
            </article>
            <article>
              <span class="heading">Address: </span>
              <span>
                {{ application.applicant.address1 }}<br />
                <template v-if="application.applicant.address2">{{ application.applicant.address2 }}<br /></template>
                {{ application.applicant.city }}, {{ application.applicant.state }} {{ application.applicant.zip }}
              </span>
            </article>
            <article>
              <span class="heading">Phone: </span>
              <span>{{ application.applicant.phone }}</span>
            </article>
            <article>
              <span class="heading">Email: </span>
              <span>{{ application.applicant.email }}</span>
            </article>
            <article>
              <span class="heading">Date of Birth: </span>
              <span>{{ application.applicant.dob }}</span>
            </article>
            <article>
              <span class="heading">Driver's License: </span>
              <span>{{ application.applicant.drivers_license }}</span>
            </article>
          </template>
          <template v-else-if="section.key === 'position'">
            <article>
              <span class="heading">Title: </span>
              <span>{{ application.position.position_title }}</span>
            </article>
            <article>
              <span class="heading">Available: </span>
              <span>{{ application.position.available_date }}</span>
            </article>
            <article>
              <span class="heading">Desired Pay: </span>
              <span>${{ application.position.desired_pay }}</span>
            </article>
            <article>
              <span class="heading">Type: </span>
              <span v-if="application.position.full_time">Full-Time,</span>
              <span v-if="application.position.part_time">Part-Time,</span>
              <span v-if="application.position.seasonal">Seasonal,</span>
            </article>
          </template>
          <template v-else-if="section.key === 'eligibility'">
            <article>
              <span class="heading">US Eligible</span>
              <span>{{ (application.eligibility.us_eligible ? "Yes" : "No") }}</span>
            </article>
            <article>
              <span class="heading">Previously Employed</span>
              <span>{{ (application.eligibility.previous_employment.value ? "Yes" : "No") }}</span>
            </article>
            <article v-if="application.eligibility.previous_employment.value">
              <span>From: {{ application.eligibility.previous_employment.from }} - To: {{ application.eligibility.previous_employment.to }}</span>
            </article>
          </template>
          <template v-else-if="section.key === 'screening_process'">
            <article v-for="(item, i) in ['bankruptcy', 'child_support', 'judgements', 'formal_caution', 'license_revoke', 'pending_prosecutions', 'prescription_drugs', 'prior_conviction', 'relations_conviction', 'terminated'] " :key="`screening_` + i">
              <template v-if="hasProperty('screening_process', item)">
                <span class="heading">{{ item | screening_process_title }}</span>
                <span>{{ (application.screening_process[item] ? "Yes" : "No") }}</span>
              </template>
            </article>
          </template>
          <template v-else-if="section.key === 'education'">
            <template v-for="(education, i) in application.education">
              <article :key="`education_` + i" class="enumerated">
                <span class="education_name">{{ education.name }} ({{ education.type }})</span>
                <span class="education_dates">From: {{ education.history.from }} - To: {{ education.history.to }}</span>
                <span class="education_location">{{ education.location.city }}, {{ education.location.state }}</span>
                <span class="education_dates">{{ (education.history.graduated ? "Graduated in " + education.history.to : "Didn't graduate") }}</span>
              </article>
            </template>
          </template>
          <template v-else-if="section.key === 'employment'">
            <template v-for="(employment, i) in application.employment">
              <article :key="`employment_` + i" class="enumerated">
                <span class="employment_title">{{ employment.title }}</span>
                <span class="employment_dates">From: {{ employment.history.from }} - To: {{ employment.history.to }}</span>
                <span class="employment_employer">{{ employment.employer }}</span>
                <span class="education_phone">{{ (employment.phone ? employment.phone : "")}}</span>
                <span class="employment_location">{{ employment.location.city }}, {{ employment.location.state }}</span>
                <span class="employment_pay">${{ employment.pay.start }} - ${{ employment.pay.end }}</span>
                <span class="employment_generic">{{ employment.duties }}</span>
                <span class="employment_generic">Reason for leaving: {{ employment.notes }}</span>
              </article>
            </template>
          </template>
          <template v-else-if="section.key === 'professional_references'">
            <template v-for="(reference, i) in application.professional_references">
              <article :key="`reference_` + i" class="enumerated">
                <span class="reference_name">{{ reference.full_name }}</span>
                <span class="reference_relationship">{{ reference.title }}</span>
                <span class="reference_company">{{ reference.company }}</span>
                <span class="reference_email">{{ reference.contact.email }}</span>
                <span class="reference_title">{{ reference.relationship }}</span>
                <span class="reference_phone">{{ reference.contact.phone }}</span>
              </article>
            </template>
          </template>
          <template v-else-if="section.key === 'military'">
            <article>
              <span class="heading">Active</span>
              <span>{{ application.military.active ? "Yes" : "No" }}</span>
            </article>
            <article v-if="!application.military.active">
              <span class="heading">Veteran</span>
              <span>{{ application.military.veteran ? "Yes" : "No" }}</span>
            </article>
            <template v-if="application.military.veteran">
              <article>
                <span class="heading">Branch of Service</span>
                <span>{{ application.military.branch }}</span>
              </article>
              <article>
                <span class="heading">Discharge Type</span>
                <span>{{ application.military.discharge.type }}</span>
              </article>
              <article>
                <span class="heading">Service Dates</span>
                <span>{{ application.military.discharge.from }} - {{ application.military.discharge.to }}</span>
              </article>
              <article>
                <span class="heading">Rank at Discharge</span>
                <span>{{ application.military.discharge.rank }}</span>
              </article>
              <article v-if="application.military.discharge.type.toString().toLowerCase() != 'honorable'">
                <span class="heading">Discharge Reason</span>
                <span>{{ application.military.discharge.notes }}</span>
              </article>
            </template>
          </template>
          <template v-else-if="section.key === 'background_authorization'">
            <article>
              <span class="heading">Consented:</span>
              <span>{{ application.background_authorization.consent ? "Yes" : "No" }}</span>
            </article>
            <template v-if="application.background_authorization.previous_addresses.length > 0">
              <h6>Previous addresses:</h6>
              <article v-for="(item, i) in application.background_authorization.previous_addresses" :key="`previous_address_` + i" class="enumerated">
                <div>{{ item.address }}</div>
                <div>{{ item.from }} - {{ item.to }}</div>
              </article>
            </template>
            <template v-if="application.background_authorization.previous_names.length > 0">
              <h6>Previous names used:</h6>
              <article v-for="(item, i) in application.background_authorization.previous_names" :key="`previous_names_` + i">
                <div>{{ item.name}}</div>
                <div>{{ item.from }} - {{ item.to }}</div>
              </article>
            </template>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'applicationView',
  props: ['application', 'showAll'],
  data: () => ({
    active_section: [0],
    sections: [
      {
        key: 'applicant',
        label: 'Applicant Information',
        order: 1
      },
      {
        key: 'position',
        label: 'Position Information',
        order: 10
      },
      {
        key: 'eligibility',
        label: 'Eligibility',
        order: 20
      },
      {
        key: 'education',
        label: 'Education',
        order: 30
      },
      {
        key: 'employment',
        label: 'Employment History',
        order: 40
      },
      {
        key: 'professional_references',
        label: 'Professional References',
        order: 50
      },
      {
        key: 'military',
        label: 'Military Service',
        order: 60
      },
      {
        key: 'background_authorization',
        label: 'Background Check',
        order: 70
      },
      {
        key: 'screening_process',
        label: 'Screening',
        order: 25
      }
    ]
  }),
  computed: {
    sections_computed: function () {
      const _sections = this.sections
      return _sections.sort((a, b) => {
        if (a.order > b.order) return 1
        else if (a.order < b.order) return -1
        else return 0
      })
    }
  },
  methods: {
    hasProperty: function (key, property) {
      if (typeof this.application[key] !== 'undefined') {
        if (typeof this.application[key][property] !== 'undefined') return true
      }
      return false
    }
  },
  filters: {
    screening_process_title: function (value) {
      const _value = value.replace('_', ' ')
      return _value
    }
  },
  mounted: function () {
    if (this.showAll) {
      this.active_section = []
      for (let i = 0; i < this.sections.length - 1; i++) {
        this.active_section.push(i)
      }
    }
  }
}
</script>

<style scoped>
h6 {
  letter-spacing: 0;
  text-transform: capitalize;
  font-size: 1em;
}
#application div.contents section {
  display: inline-block;
  width: 49%;
  margin: 0 0 20px 0;
  border: solid 1px #999;
  background-color: #eee;
  padding: 15px;
  border-radius: 10px;
  font-size: .9em;
}
/* EXPANSION PANELS */
div.contents .application_section_header {
  color: #000 !important;
  background-color: #dedede;
}
div.contents .application_section_content {
  padding: 10px 0 0 0;
}
/* PANEL CONTENT */
div.contents .application_section_content article {
  padding: 4px 0;
}
div.contents article.enumerated {
  border-bottom: dashed 1px #ccc;
  padding-bottom: 20px;
}
div.contents article.enumerated:last-child {
  padding-bottom: unset;
  border-bottom: unset;
}
div.contents .application_section_content article span {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
div.contents .application_section_content article span.heading {
  font-weight: 600;
  width: 200px;
  margin: 0 0 1px 0;
}
/* EDUCATION */
div.contents .application_section_content .education_name {
  display: inline-block;
  font-weight: 600;
  font-size: 1.1em;
  width: 60%;
}
div.contents .application_section_content .education_location {
  display: inline-block;
  width: 60%;
}
div.contents .application_section_content .education_dates {
  display: inline-block;
  width: 28%;
  text-align: right;
}
div.contents .application_section_content .education_phone {
  display: inline-block;
  width: 28%;
  text-align: right;
}
/* EMPLOYMENT */
div.contents .application_section_content .employment_title {
  display: inline-block;
  font-weight: 600;
  font-size: 1.1em;
  width: 60%;
}
div.contents .application_section_content .employment_employer,
  div.contents .application_section_content .employment_location {
  display: inline-block;
  width: 60%;
}
div.contents .application_section_content .employment_dates,
  div.contents .application_section_content .employment_pay {
  display: inline-block;
  width: 28%;
  text-align: right;
}
div.contents .application_section_content .employment_generic {
  display: inline-block;
  width: 100%;
  margin: 10px 0 0 0;
}
/* REFERENCES */
div.contents .application_section_content .reference_name {
  display: inline-block;
  width: 60%;
  font-weight: 600;
  font-size: 1.1em;
}
div.contents .application_section_content .reference_company,
  div.contents .application_section_content .reference_title {
  display: inline-block;
  width: 60%;
}
div.contents .application_section_content .reference_relationship,
  div.contents .application_section_content .reference_email,
  div.contents .application_section_content .reference_phone {
  display: inline-block;
  width: 28%;
  text-align: right;
}
/* SCREENING PROCESS */
div.contents .screening_process article span.heading {
  text-transform: capitalize !important;
}

@media print {
  #application div.contents section {
    width: 49%;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: .9em;
    break-inside: avoid-page;
    float: left;
  }
  div.contents section div.enumerated {
    padding: 0;
  }
  div.contents .application_section_content {
    display: block !important;
  }
}
@media screen and (max-width: 480px) {
  #application div.contents section {
    display: inline-block;
    width: 100%;
    padding: 10px;
  }
  div.contents section span.heading {
    display: block;
    margin: 6px 0 0 0;
  }
  div.contents section div.enumerated {
    padding: 8px 10px;
  }
}
</style>
