<template>
  <div>
    <section class="main">
      <div class="hero parallax"></div>

      <article>
        <h2>404 | Page Not Found</h2>

        <h3>Oops! Looks like we can't the exact page you are looking for :(</h3>
        <div class="alternatives">
          Please use the following sitemap to find your destination or visit our <router-link to="/">home page</router-link>.
          <ul>
            <li>
              <router-link to="/about">About</router-link>
              <ul>
                <li><router-link to="/about/mission">Our Mission</router-link></li>
                <li><router-link to="/about/testimonials">Testimonials</router-link></li>
              </ul>
            </li>
            <li>
              <router-link to="/services">Our Services</router-link>
              <ul>
                <li v-for="(item, i) in $store.state.services" :key="i">
                  <router-link :to="'/services/' + getUrlFromName(item)">{{ item }}</router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/careers">Careers</router-link>
              <ul>
                <li><router-link to="/careers">Apply Today!</router-link></li>
              </ul>
            </li>
            <li>
              <router-link to="/contact-us">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </article>

      <call-to-action :link_back=false></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  data: () => ({
  }),
  components: {
  },
  methods: {
  }
}
</script>

<style scoped>
section.main article div.alternatives {
  margin: 10px 0 0 0;
  font-size: 1.2em;
  overflow: hidden;
}
section.main article div.alternatives > ul {
  margin: 35px 0 0 0;
}
section.main article div.alternatives > ul > li {
  font-size: 1.3em;
  font-weight: 600;
  float: left;
  width: 24%;
  margin: 0 1% 5% 0;
  list-style: none;
  text-align: left;
  padding: 15px;
  background-color: rgba(0,0,0,.5);
  border-radius: 15px;
  color: #2a7afa;
  height: 300px;
  text-align: center;
}
section.main article div.alternatives > ul > li > ul {
  color: #fff;
  font-weight: 400;
  font-size: .75em;
  line-height: 1.6em;
  list-style: none;
  text-align: center;
  margin: 10px 0 0 0;
}
section.main article div.alternatives ul a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1190px){
  section.main article h3 {
    font-size: 1.2em;
    letter-spacing: 1px;
  }
  section.main article div.alternatives > ul > li {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 1000px){
  section.main article h3 {
    font-size: 1.1em;
  }
}
@media screen and (max-width: 850px){
  section.main article div.alternatives > ul > li {
    width: 49%;
    padding: 10px 15px;
    height: 240px;
    margin: 0 1% 3% 0;
  }
  section.main article div.alternatives > ul > li > ul {
    line-height: 1.4em;
  }
  section.main article div.alternatives > ul > li:nth-child(3) { height: 90px; }
  section.main article div.alternatives > ul > li:nth-child(4) { height: 90px; }
}
@media screen and (max-width: 650px){
  section.main article h3 {
    font-size: 1.2em;
    letter-spacing: 0;
  }
  section.main article div.alternatives > ul > li {
    width: 48%;
    margin: 0 2% 3% 0;
  }
}
@media screen and (max-width: 480px){
  section.main article div.alternatives > ul > li > ul {
    line-height: 1.3em;
    font-size: .7em;
  }
}
@media screen and (max-width: 390px){
  section.main article div.alternatives > ul > li {
    width: 100%;
    float: none;
    display: block;
    margin: 0 0 3% 0;
    height: auto;
    padding: 15px;
  }
  section.main article div.alternatives > ul > li > ul {
    line-height: 1.5em;
  }
  section.main article div.alternatives > ul > li:nth-child(3) { height: auto; }
  section.main article div.alternatives > ul > li:nth-child(4) { height: auto; }
}
</style>
