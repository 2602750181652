'use strict'

import Vue from 'vue'
import axios from 'axios'
const baseURL = process.env.VUE_APP_API_HOST + '/api'
console.log('baseURL: ' + baseURL)
const config = {
  baseURL: baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: false // Check cross-site Access-Control
}
console.log(config)

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    if (config.url !== '/api/login') {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('at')
    }
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

// eslint-disable-next-line no-unused-vars
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
