<template>
  <div>
    <section class="main">
      <div class="hero parallax">

      </div>
      <article>
        <h3>Reliable, affordable and certified security service for everyone!</h3>
        <p class="intro">
          Guaranteed reliability, operating process and highest standards of quality!<br />
          24/7 on-call service that you can trust!<br />
        </p>

        <div class="flex-container">
          <aside v-for="(article, i) in articles" :key="i">
            <i :class="article.icon"></i>
            <h2>{{  article.heading }}</h2>
            <p>{{ get_article_text_cropped(article.text, 250) }}</p>
            <button @click.stop="goToPage('About')">LEARN MORE</button>
          </aside>
        </div>

        <testimonials></testimonials>

        <call-to-action :link_back=false></call-to-action>

      </article>
    </section>
  </div>
</template>

<script>
import testimonials from '@/components/testimonials'
export default {
  name: 'Home',
  data: () => ({
    articles: [
      {
        heading: 'Reliable',
        icon: 'fas fa-shield-alt',
        text: 'Our company\'s culture is based on the achievement of excellence in every aspect of providing security and protection, and exceeding our customers\' expectations and needs, with integrity and professionalism. With that in mind, all our Security Officers are certified, insured, bonded and committed to their tasks.',
        href: ''
      },
      {
        heading: 'Affordable',
        icon: 'fas fa-hand-holding-usd',
        text: 'Our services are designed for small businesses and large corporations with the commitment to highest quality, dedication, and maximum safety. If you feel that your current security program needs improvement, please allow us the opportunity to conduct a complementary security survey for you. The survey will allow us to give you an expert analysis of your property\'s safety and security program. Furthermore, if you are in need of a new program, we will develop an integrated plan to cover your needs and focus on recommendations that fit within your budget.',
        href: ''
      },
      {
        heading: 'Certified',
        icon: 'fas fa-graduation-cap',
        text: 'All our employees undergo company\'s pre-employment screening before being hired: comprehensive criminal background check and fingerprinting; participate in the training to upgrade their knowledge in state laws, patrol techniques and self-defense skills annually.',
        href: ''
      }
    ]
  }),
  components: {
    testimonials
  },
  methods: {
    get_article_text_cropped: (text, limit) => {
      if (text.length <= limit) return text
      else return text.substr(0, limit - 3) + '...'
    }
  }
}
</script>

<style scoped>
section.main article h3 {
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 1.8em;
  letter-spacing: 1px;
  text-shadow: 1px 1px black;
}
section.main article p.intro {
  text-align: center;
  margin: 20px 0 40px 0;
  font-size: 1.3em;
}
</style>
