<template>
  <div id="bg_overlay" :class="show_overlay" @click.stop="hideOverlay"></div>
</template>

<script>
export default {
  name: 'overlay',
  data: () => ({
    show: false,
    animations: {
      overlay: 'hidden'
    }
  }),
  methods: {
    hideOverlay: function () {
      if (this.$store.getters.overlay.allow_dismissal) {
        this.setOverlayStatus(false, true, false)
      }
    }
  },
  computed: {
    show_overlay: function () {
      const $ = require('jquery')
      let zIndex = 11
      if (this.$store.getters.overlay.allow_dismissal) zIndex = 9
      if (this.$store.getters.overlay.show) {
        $('#bg_overlay').css({ zIndex: zIndex })
        $('body').css({
          overflow: 'hidden',
          position: 'fixed'
        })
        return 'fade-in'
      } else {
        $('#bg_overlay').css({ zIndex: -1 })
        $('body').css({
          overflow: 'unset',
          position: 'unset'
        })
        return 'hidden'
      }
    }
  }
}
</script>

<style scoped>
#bg_overlay {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(110,110,110,.9);
  width: 100%;
  height: 100%;
}
</style>
