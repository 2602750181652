<template>
  <div>
    <section id="content">

      <div class="flex-container">
        <article class="job_postings" v-if="false">
          <h3>Current Job Postings</h3>
          <v-btn
              outlined
          >
            <v-icon>mdi-plus</v-icon>
            CREATE JOB
          </v-btn>
          <div class="items">
            hello
          </div>
        </article>
        <v-card
          rounded
          color="#cdcdcd"
          width="100%"
          height="80vh"
        >
          <v-card-title>Pending Applications</v-card-title>
          <v-card-text class="py-0">
            <div class="headers flex-container">
              <span class="actions"></span>
              <span class="date">Date</span>
              <span class="step">Last Step</span>
              <span class="step">Onboarding</span>
              <span class="name">Name</span>
              <span class="position">Position</span>
            </div>
            <div class="items">
              <div class="application flex-container" v-for="(application, i) in validated_applications_computed" :key="'application_' + i">
                <span class="actions">
                  <v-btn
                    icon
                    small
                    @click.stop="delete_application(application)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </span>
                <span class="date">{{  application.created_at | friendly_date }}</span>
                <span class="step">{{  application.step | last_step_description }}</span>
                <span class="step">
                  <template v-if="get_onboarding_status(application) === 'Finished'">
                    <a href="#" @click.stop="download_documents(application)" title="Download Documents">Download</a>
                  </template>
                  <template v-else>
                    {{ get_onboarding_status(application) }}
                  </template>
                </span>
                <span class="name" @click.stop="view_application(i)">{{  application.applicant.first + " " + application.applicant.last }}</span>
                <span class="position">{{  application.position.position_title }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>

    </section>

    <template v-if="Object.keys(applications).length > 0 && idx >= 0">
      <div id="application" width="90%">
        <div class="title">
          <h3>{{ (validated_applications_computed[idx].position.position_title ? validated_applications_computed[idx].position.position_title : "Position Title Not Specified") }}</h3>
        </div>
        <div class="action_buttons">
          <router-link :to="{ path: '/admin/print/application/' + this.idx }" target="_blank">
            <v-icon>mdi-printer</v-icon>
          </router-link>
          <a href="#" @click.stop="close_view">
            <v-icon>mdi-close</v-icon>
          </a>
        </div>
        <applicationView :application="validated_applications_computed[idx]"></applicationView>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import applicationView from '@/components/applicationView'
export default {
  name: 'Dashboard',
  data: () => ({
    idx: -1,
    applications: []
  }),
  components: { applicationView },
  methods: {
    get_onboarding_status: function (application) {
      if (!application.onboarding) return 'N/A'
      else if (application.onboarding.complete) return 'Finished'
      else if (application.onboarding.data) return 'Step: ' + application.onboarding.step
      else return 'Not Started'
    },
    delete_application: function (item) {
      if (window.confirm('Are you sure you want to delete this application?')) {
        this.requestHandler.doDelete('application', '/' + item.id)
          .then(response => {
            console.log(response)
            if (response.data.success) {
              const idx = this.applications.findIndex((app) => app.id === item.id)
              if (idx > -1) this.applications.splice(idx, 1)
            }
          })
      }
    },
    view_application: function (i) {
      this.setOverlayStatus(true, false, false)
      console.log(i)
      this.idx = i
    },
    close_view: function () {
      this.$store.commit('setOverlayStatus', {
        show: false,
        allow_dismissal: true,
        expend_menu: false
      })
      this.idx = -1
    },
    download_documents: function (application) {
      let zipName = application.applicant.first + '_' + application.applicant.last + '-' + application.position.position_title + '.zip'
      zipName = zipName.replace(' ', '_').toLowerCase()
      return this.axios({
        url: '/application/onboarding/file-download/' + application.onboarding.code,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', zipName)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
    }
  },
  computed: {
    validated_applications_computed: function () {
      const _applications = []
      const requiredProperties = ['applicant', 'background_authorization', 'position', 'employment', 'eligibility', 'education', 'disclaimer', 'id', 'military', 'professional_references', 'step']
      this.applications.forEach(application => {
        let valid = true
        requiredProperties.forEach(property => {
          if (typeof application[property] === 'undefined') {
            valid = false
            console.error('Missing property [' + property + ']!')
            return false
          }
        })
        if (valid) _applications.push(application)
      })

      console.log(_applications)
      return _applications
    }
  },
  filters: {
    friendly_date: function (value) {
      return moment(value).format('MM/DD/YYYY')
    },
    last_step_description: function (step) {
      switch (step) {
        case 1: return 'Applicant Information'
        case 2: return 'Position Details'
        case 3: return 'Employment Eligibility'
        case 4: return 'Screening Process'
        case 5: return 'Education'
        case 6: return 'Employment History'
        case 7: return 'References'
        case 8: return 'Military Service'
        case 9: return 'Background Check Consent'
        case 10: return 'Disclaimer'
        case 11: return 'Finished'
      }
    }
  },
  created () {
    this.requestHandler.doGet('application', '/all')
      .then(response => {
        this.applications = response.data.data
      })
  }
}
</script>

<style scoped>
#content {
  color: black;
  padding: 120px 15px 0 15px;
  width: 100%;
}
#content div.flex-container article {
  position: relative;
  margin: 30px 0 0 0;
  background: rgba(0,0,0,.25);
  background: linear-gradient(180deg, rgba(0,0,0,.5) 0%, transparent 100%);
  padding: 20px;
  width: 100%;
  border-radius: 15px;
}
#content div.flex-container article h3 {
  font-size: 1.3em;
  color: #2a7afa;
  text-shadow: 1px 1px 2px #fff;
  letter-spacing: 0px;
  padding-bottom: 10px;
  border-bottom: dashed 1px #999;
}
#content div.flex-container article > button {
  position: absolute;
  right: 15px;
  top: 10px;
  color: black !important;
  border-color: black;
  font-weight: 600;
}
.headers  span {
  font-weight: 600;
  width: 19%;
  display: inline-block;
}
.items {
  overflow-y: auto;
  max-height: 480px;
}
.items span {
  width: 19%;
  display: inline-block;
}
.items > div > span.actions button {
  margin: 2px 3px;
  color: #666 !important;
}
span.actions, span.date {
  width: 6%;
}
span.step {
  width: 12%;
}
.items span.name {
  color: darkblue;
  text-transform: capitalize;
}
.items span.name:hover {
  text-decoration: underline;
  cursor: pointer;
}
.position {
  width: 34% !important;
  text-transform: capitalize;
}
#application {
  z-index: 13;
  background-color: rgba(100,100,100,1);
  width: 90%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border-radius: 15px;
  box-shadow: 10px 10px 25px #222;
  padding: 15px 15px;
}
#application div.title {
  width: 90%;
  height: auto;
}
#application div.action_buttons {
  display: inline;
  position: absolute;
  right: 15px;
  width: auto;
  top: 15px;
}
#application div.action_buttons > a {
  text-decoration: none;
  margin-left: 10px;
}
#application div.action_buttons > a > i {
  color: white;
}
#application div.contents {
  background-color: white;
  width: 100%;
  color: black;
  margin: 15px 0 0 0;
  border-radius: 10px;
  padding: 15px;
  height: 600px;
  overflow-y: auto;
}
@media screen and (max-width: 480px){
  #content div.flex-container article.applications .headers span:nth-child(3){ display: none; }
  #content div.flex-container article.applications .headers span:nth-child(5){ display: none; }
  #content div.flex-container article.applications .items span:nth-child(3){ display: none; }
  #content div.flex-container article.applications .items span:nth-child(5){ display: none; }
  #content div.flex-container article.applications .items { font-size: .9em; }
  #content div.flex-container article.applications span.actions { width: 20%; }
  #content div.flex-container article.applications span {
    width: 40%;
  }
  #content div.flex-container article.applications .items span.date, #content div.flex-container article.applications .items span.name {
    padding: 8px 0 0 0;
  }
  #application {
    width: 96%;
    margin: 20px auto;
    border: solid 1px #222;
    padding: 10px;
  }
  #application div.action_buttons a:first-child {
    display: none;
  }
}
</style>
