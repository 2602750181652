<template>
  <nav>
    <i
        id="menu_hamburger"
        class="fa fa-bars"
        aria-hidden="true"
        @click.stop="toggleMenu"
        v-show="showBurger"
    ></i>
    <ul :class="animateMenu">
      <li v-for="(item, i) in nav_links" :key="i">
        <template v-if="item.action.type === 'page'">
          <a :href="item.action.location" @click.stop="navigate($event, item.label)" :aria-haspopup="(item.children && Object.keys(item.children).length > 0)">{{ item.label }}</a>
          <span v-if="(item.children && Object.keys(item.children).length > 0)" class="expand"></span>
        </template>
        <ul v-if="item.children && Object.keys(item.children).length">
          <li v-for="(item, i) in item.children" :key="i">
            <template v-if="item.action.type === 'page'">
              <a :href="item.action.location" @click.stop="navigate($event, item.label)">{{ item.label }}</a>
            </template>
          </li>
        </ul>
      </li>
    </ul>

  </nav>
</template>

<script>
import store from '@/store'
export default {
  name: 'gammaNav',
  data: () => ({
    animations: {
      menu: 'hidden'
    },
    nav_links: [
      {
        label: 'Home',
        action: {
          type: 'page',
          location: 'home'
        },
        children: []
      },
      {
        label: 'About',
        action: {
          type: 'page',
          location: 'about'
        },
        children: [
          { label: 'Mission', action: { type: 'page', location: 'about/mission' } },
          { label: 'Testimonials', action: { type: 'page', location: 'about/testimonials' } }
        ]
      },
      {
        label: 'Services',
        action: {
          type: 'page',
          location: 'services'
        },
        children: [
          // { label: "Healthcare Security", action: { type: "page", location: "services/healthcare-security" }},
          // { label: "Hospitality Security", action: { type: "page", location: "services/hospitality-security" }},
          // { label: "Commercial Security", action: { type: "page", location: "services/commercial-security" }},
          // { label: "Education Security", action: { type: "page", location: "services/education-security" }},
          // { label: "Construction Site Security", action: { type: "page", location: "services/construction-site-security" }},
          // { label: "Executive Security & VIP Protection", action: { type: "page", location: "services/executive-security-and-vip-protection " }},
          // { label: "Cyber Security", action: { type: "page", location: "services/cyber-security" }},
          // { label: "Security Consulting", action: { type: "page", location: "services/security-consulting" }},
          // { label: "Special Event Security", action: { type: "page", location: "services/special-event-security" }}
        ]
      },
      {
        label: 'Careers',
        action: {
          type: 'page',
          location: 'careers'
        }
      },
      {
        label: 'Contact Us',
        action: {
          type: 'page',
          location: 'contact-us'
        }
      }
    ],
    is_mobile: false
  }),
  methods: {
    navigate: function (e, routeName) {
      e.stopImmediatePropagation()
      e.preventDefault()

      if (this.is_mobile) {
        this.toggleMenu()
      }
      this.goToPage(routeName)
    },
    displayMenu: function () {
      if (this.$store.getters.overlay.expandMenu) {
        this.animations.menu = 'fade-in'
        this.setOverlayStatus(this.$store.getters.overlay.show, true, this.$store.getters.overlay.expandMenu)
      } else {
        this.animations.menu = 'fade-out'
        const instance = this
        setTimeout(function () {
          instance.animations.menu = 'hidden'
        }, 2000)
      }
    },
    toggleMenu: function () {
      console.log('In toggle menu')
      this.$store.getters.overlay.expandMenu = !this.$store.getters.overlay.expandMenu
      this.$store.getters.overlay.show = !this.$store.getters.overlay.show
      this.setOverlayStatus(this.$store.getters.overlay.show, true, this.$store.getters.overlay.expandMenu)
      this.displayMenu()
    },
    setMenuState: function () {
      if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
        if (window.innerWidth <= 650) {
          this.is_mobile = true
          this.$store.getters.overlay.expandMenu = false
        }
      } else {
        this.animations.menu = 'fade-in'
      }
    }
  },
  computed: {
    animateMenu: function () {
      if ((this.is_mobile && this.$store.getters.overlay.expandMenu) || (!this.is_mobile)) return 'fade-in'
      return 'hidden'
    },
    showBurger: function () {
      if (this.is_mobile && this.$store.getters.overlay.expandMenu === false) return true
      return false
    }
  },
  mounted () {
    this.setMenuState()

    window.addEventListener('resize', () => {
      this.setMenuState()
    })
  },
  created () {
    this.nav_links.forEach((link) => {
      if (link.label === 'Services') {
        store.state.services.forEach((item) => {
          link.children.push({ label: item, action: { type: 'page', location: 'services/' + this.getUrlFromName(item) } })
        })
      }
    })
  }
}
</script>

<style scoped>
/* navigation */
nav {
  position: fixed;
  top: 25px;
  right: 10px;
  z-index: 10;
}
nav i {
  color: white;
}
nav > ul {
}
nav ul li {
  position: relative;
  list-style: none;
  float: left;
  font-size: 1em;
  padding: 10px 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: #000;
  background-color: rgba(255,255,255,.65);
  border-right: dashed 1px rgba(0,0,0,.125);
}
nav ul li:first-child {
  border-radius: 15px 0 0 15px;
}
nav ul li:last-child {
  border-radius: 0 15px 15px 0;
}
nav ul li:hover > ul{
  display: block;
}
nav > ul > li a {
  display: block;
  color: inherit;
  text-decoration: none;
}
nav > ul > li:first-child {
}
nav > ul > li:last-child {
  border-right: 0;
}
nav > ul > li:hover {
  /*background-color: rgba(255,255,255,.85);*/
  /*box-shadow: 5px 5px 10px #000;*/
  font-weight: 600;
  color: #2a7afa;
  background-color: white;
}

/* 2nd level */
nav ul ul {
  background-color: rgba(255,255,255,1);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 15px;
}
nav > ul ul > li {
  position: relative;
  width: 220px;
  text-align: left;
  float: left;
  border-bottom: dashed 1px rgba(0,0,0,.125);
  background-color: transparent;
}
nav > ul ul > li:hover {
  color: #2a7afa;
  font-weight: 600;
}
nav > ul ul > li:last-child {
  border-bottom: 0;
}
#menu_hamburger {
  position: fixed;
  top: 30px;
  right: 15px;
  opacity: .8;
}

/* media queries */
@media screen and (max-width: 1280px){
  nav ul > li {
    padding: 10px 15px;
    font-size: 1em;
  }
}
@media screen and (max-width: 1160px){
  nav ul > li {
    padding: 10px 13px;
    font-size: .9em;
  }
}
@media screen and (max-width: 1028px){
  nav ul > li {
    padding: 10px 13px;
    font-size: .85em;
  }
}
@media screen and (max-width: 980px){
  nav ul > li {
    padding: 8px 10px;
    font-size: .85em;
  }
}
@media screen and (max-width: 920px){
  nav > ul > li {
    background-color: rgba(255,255,255,.5);
  }
}
@media screen and (max-width: 770px){
  nav {
    position: fixed;
    top: 70px;
    right: 10px;
  }
}
@media screen and (max-width: 650px) {
  body {
    overflow: hidden;
  }
  nav {
    top: 1%;
    right: 1%;
    width: 98%;
    box-shadow: 5px 5px 20px #000;
    z-index: 13;
  }
  nav ul,
  nav ul ul,
  nav ul ul ul {
    position: static;
  }
  nav > ul {
    z-index: 14;
  }
  nav > ul > li {
    padding: 13px 30px;
    display: block;
    width: 100%;
    font-size: 1.2em;
    background-color: #000;
    color: #fff;
    border: solid 1px rgba(255,255,255,.75);
    box-sizing: border-box;
    letter-spacing: 3px;
    position: relative;
  }
  nav ul li:first-child, nav ul li:last-child {
    border-radius: 0;
  }
  nav > ul > li:hover {
    background-color: black;
  }
  nav > ul > li a {
    display: inline-block;
    color: white;
    font-weight: 500;
    text-decoration: none;
  }
  nav > ul > li a:hover {
    color: #2a7afa;
  }
  nav > ul > li .expand {
    display: block;
    width: 0px; height: 0px;
    position: absolute;
    top: 22px; right: 20px;
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
  nav > ul > li .expand:hover > ul {
    display: block;
  }
  nav > ul > li:first-child { border-radius: 0; }
  nav > ul > li:last-child { border-radius: 0; border: solid 1px rgba(255,255,255,.75); }
  nav > ul > li:hover {
    color: #2a7afa;
    font-weight: 600;
  }
  nav > ul > li > ul {
    margin: 10px 0 0 0;
  }
  nav > ul > li > ul > li {
    width: 100%;
    background-color: rgba(255,255,255,.125);
    color: #fff;
    text-align: left;
    border-bottom: dashed 1px rgba(255,255,255,.125);
    padding-left: 30px;
  }
  nav > ul > li > ul > li:last-child {
    border-bottom: 0;
  }
  nav::after {
    top: 30px;
  }
  /*nav ul,*/
  /*nav ul ul,*/
  /*nav ul ul ul {*/
  /*  position: static;*/
  /*}*/
  /*nav > ul > li {*/
  /*  position: relative;*/
  /*}*/
  /*nav > ul > li a {*/
  /*  display: inline-block;*/
  /*  color: inherit;*/
  /*  text-decoration: none;*/
  /*}*/
  /*nav > ul > li > ul {*/
  /*  margin: 10px 0 0 0;*/
  /*}*/
  /*nav > ul > li > ul > li {*/
  /*  width: 100%;*/
  /*  background-color: rgba(255,255,255,.125);*/
  /*  color: #fff;*/
  /*  text-align: center;*/
  /*  border-bottom: dashed 1px rgba(255,255,255,.125);*/
  /*}*/
  /*nav > ul > li > ul > li:last-child {*/
  /*  border-bottom: 0;*/
  /*}*/
}
</style>
