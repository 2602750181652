<template>
  <div>
    <!-- Wrapper -->
    <div id="wrapper">
      <gammaNav v-show="!isLogin"></gammaNav>

      <overlay></overlay>

      <gammaHeader v-show="!isLogin"></gammaHeader>

      <section id="content">
        <transition
            name="fade"
            mode="out-in"
        >
          <router-view/>
        </transition>
      </section>

      <gammaFooter v-show="!isLogin"></gammaFooter>
    </div>
    <!-- Bg -->
    <div id="bg"></div>
  </div>
</template>

<script>
import gammaHeader from '@/components/layout/header'
import gammaNav from '@/components/layout/nav'
import gammaFooter from '@/components/layout/footer'
import overlay from '@/components/overlay'
import store from '@/store'
import router from '@/router'
export default {
  name: 'DefaultLayout',
  components: { gammaHeader, gammaNav, gammaFooter, overlay },
  computed: {
    showHeader: function () {
      return store.state.show_header
    },
    isLogin: function () {
      return router.apps[0]._route.name === 'Login'
    }
  }
}
</script>

<style scoped>

</style>
