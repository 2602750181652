<template>
    <div>
        <header>
            <div>
                <h1>New Hire On-boarding Process </h1>
            </div>
        </header>
        <section id="content">
            <template v-if="found_onboarding_computed">
              <template v-if="started === false">
                <h3>Thank you for submitting your application for the position of <strong>{{ position_title_computed }}</strong>.</h3>
                <div class="intro">
                    You may <a class="download" :href="`${publicPath}Hiring%20Package_2021.pdf`" download="Hiring_Packet_2021.pdf">download</a> our Employee Handbook for your records.
                </div>
                <div class="intro">
                    Please complete your on-boarding process by reviewing our Policies &amp; Procedures and sign-off on all sections. If at any point you need to stop, you may always return to the last page you completed.
                </div>
                <div class="intro">
                    Please save the following url (or bookmark this page) so you can can return and continue at any point:
                    <br /><span class="return_url">{{ return_url_computed }}</span>
                </div>
                <div class="intro">
                  <v-btn
                      outlined
                      x-large
                      class="begin_onboarding_button"
                      @click.stop="started = true"
                  >
                      BEGIN
                  </v-btn>
                </div>
              </template>
              <template v-else>
                <template v-if="onboarding.step === 1">
                  <h3>Policies &amp;Rules</h3>
                  Please read each policy/rule and click 'I Agree' button after to move to the next step.
                  <div class="policies">
                      <div class="policy">
                          <div class="policy_step">Step: {{ policy_idx }} out of {{ policies_rules.length }}</div>
                          <div class="policy_label" v-html="policy_label_computed"></div>
                          <div class="policy_action">
                            <div class="error_message" v-if="error_message">{{ error_message }}</div>
                            <v-btn
                              outlined
                              x-large
                              class="begin_onboarding_button"
                              @click.stop="advance_policy"
                            >
                              I AGREE
                            </v-btn>
                        </div>
                      </div>
                  </div>
                </template>
                <template v-else-if="onboarding.step === 2">
                  <h3>Preliminary Questions</h3>
                  <div class="preliminary">
                      <div class="policy">
                        <div class="policy_step">Step: {{ policy_idx }} out of {{ preliminary_questions.length }}</div>
                        <div class="policy_label bold" v-html="preliminary_questions_label_computed"></div>
                        <div v-if="preliminary_questions[policy_idx -1].disclaimer" class="disclaimer" v-html="preliminary_questions_disclaimer_computed"></div>
                          <template v-if="preliminary_questions[policy_idx -1].type === 'text'">
                            <input type="text" v-model="preliminary_questions[policy_idx -1].value" />
                          </template>
                          <template v-else-if="preliminary_questions[policy_idx -1].type === 'boolean'">
                            <v-radio-group v-model="preliminary_questions[policy_idx -1].value" row>
                              <v-radio value="true" label="Yes"></v-radio>
                              <v-radio value="false" label="No"></v-radio>
                            </v-radio-group>
                          </template>
                          <div class="policy_action">
                            <div class="error_message" v-if="error_message">{{ error_message }}</div>
                            <v-btn
                              outlined
                              x-large
                              class="begin_onboarding_button"
                              @click.stop="advance_preliminary"
                            >
                              I AGREE
                            </v-btn>
                        </div>
                      </div>
                  </div>
                </template>
                <template v-else-if="onboarding.step === 3">
                  <h3>Schedule &amp; Availability</h3>
                  <div class="preliminary">
                    <div class="policy">
                      <div class="policy_label">
                        <strong>Please fill-out your availability using the options below. If you cannot work on a specific day at all, select 'OFF'</strong>
                        <div class="disclaimer">
                          * Your availability significantly affects your hours per week but it is not the only factor. More available you are, more hours you receive but it is not guaranteed
                        </div>
                        <table class="availibility_table">
                          <thead>
                            <tr>
                              <th>DAY</th>
                              <th>FROM</th>
                              <th>TO</th>
                              <th>TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(day, i) in availability" :key="`day_` + i">
                              <td>{{ day.key.toUpperCase() }}</td>
                              <td>
                                <select class="hours" v-model="day.from">
                                  <option value="off" selected>OFF</option>
                                  <option v-for="(slot, i) in hours" :key="`${i}_hour_from`" :value="slot.label">{{ slot.label }}</option>
                                </select>
                              </td>
                              <td>
                                <select class="hours" v-model="day.to">
                                  <option value="off" selected>OFF</option>
                                  <option v-for="(slot, i) in hours" :key="`${i}_hour_to`" :value="slot.label">{{ slot.label }}</option>
                                </select>
                              </td>
                              <td>
                                <span v-html="get_hours_per_day(day)"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="total_hours">Total Hours: {{ total_hours_computed }}</div>
                        <div class="error_message" v-if="error_message">{{ error_message }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="btn_next">
                      <v-btn
                          x-large
                          outlined
                          @click.stop="advance_hours()"
                      >
                          NEXT
                      </v-btn>
                  </div>
                </template>
                <template v-else-if="onboarding.step === 4">
                  <h3>Documentation</h3>
                  <div class="documents">
                    <div class="policy">
                      <div class="policy_label">Please attach the following documents:</div>
                      <template v-for="(item, i) in required_documents">
                        <div :key="`documents_` + i">
                          <label >{{ item.label }}</label>
                          <form enctype="multipart/form-data" novalidate>
                            <input type="file"
                            accept=".png,.jpg,.pdf,.jpeg,.gif,.tiff,.doc,.docx"
                            v-on:change="prep_file_for_upload(item, $event)"
                            :ref="`documentUpload_${item.key}`"
                            />
                            <img :id="item.key + `_preview`" class="file_upload_preview" src="#" :alt="item.label" />
                          </form>
                        </div>
                      </template>
                      <div class="error_message" v-if="error_message">{{ error_message }}</div>
                      <!-- <div class="selected_files" v-html="added_files_computed"></div> -->
                    </div>
                  </div>
                  <div class="btn_next" v-if="this.files.length > 0">
                      <v-btn
                          x-large
                          outlined
                          @click.stop="onboarding_next()"
                          :disabled="submitting"
                      >
                          SUBMIT DOCUMENTS
                      </v-btn>
                  </div>
                </template>
                <template v-if="is_complete">
                  <h3>Thank you for completing your on-boarding process!</h3>
                  <div class="intro">
                    We will be contacting you after your background check is complete to discuss specific assignments.
                  </div>
                  <div class="intro">
                    In the meanwhile, feel free to <a class="download" :href="`${publicPath}Hiring%20Package_2021.pdf`" download="Hiring_Packet_2021.pdf">download</a> and review our Employee Handbook.
                  </div>
                </template>
              </template>
            </template>
            <template v-else>
                <h3>Oops! We're sorry we couldn't find your application.</h3>
                <p>
                    Please contact are office so we try to help.
                </p>
                <div>
                    Phone: {{ $store.state.contact.IL.phone }}<br />
                    Email: {{ $store.state.contact.IL.email }}<br />
                </div>
                <p>
                    You will be redirected to our homepage in <strong>{{ redirect_time_computed }}</strong> seconds
                </p>
            </template>
        </section>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'OnBoarding',
  data: () => ({
    submitting: false,
    publicPath: process.env.BASE_URL,
    started: false,
    policies_rules: [
      {
        label: 'GammaTeam Security employee shall provide the Company with all required documents (State ID/Driving license, SSN, valid PERC card, a 20-hour training certificate, direct deposit information, photo; firearm training certificate (if applicable), FOID card (if applicable); and fill out all the Company forms (Employment application; Employee’s Statement, Availability form, Pay Periods form); and/or any other forms/get_hours_per_day if applicable when hired. Applicants with no full required document package won’t be on schedule until the required is submitted.',
        signed: false
      },
      {
        label: 'Full time is 32-40 hours per week. Part time is 8-24 hours. No GammaTeam Security employees are hired for a full-time position. The company may provide you with more than 24 hours per week depending upon available work but does not guarantee it. There is no set schedule. Schedule is different from week to week based on the Company needs, your availability, location and willingness to work.',
        signed: false
      },
      {
        label: 'NO GammaTeam Security employees are hired for only one job site. Employee may be rotated between different job sites based on the Company needs.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall be paid 1.5 time more than a regular pay per hour if he/she exceeds 40 hours per week while working for GammaTeam Security. No overtime is guaranteed.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall answer the Company calls including calls from the Office, Operations Managers, Supervisors, Management. If the call cannot be taken at the time the call is placed, or text is received, the officer shall return the call or respond by text within the next 15 minutes.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee is not allowed to take breaks without permission and scheduled time. The break (30 min) is paid by Gamma Team Security, Inc.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall arrive at his/her work location 10 minutes prior to the start of his/her shift and be on post at the assigned time. One hour before the shift, he/she shall inform an Operations Manager or Site Supervisor that he/she is ready for the shift and will be there at the assigned time.',
        signed: false
      },
      {
        label: 'In the event an employee calls off/does not show up/late to work or acts in any other way harming business of GammaTeam Security company, GammaTeam Security reserves the right to apply all applicable deductions to future paychecks of such employee based on reports that Operation Department submits to Accounting Department in regards to employee’s wrong doings.',
        signed: false
      },
      {
        label: `<div>
        All GammaTeam Security officers shall arrive to the site 10 minutes prior to the shift start time.<br />
        If GammaTeam Security officers are late, they are subject to the following:</div>
          <div style="margin: 15px 0 0 0">&bull; Up to 15 minutes late – timesheet is cut to 1 hour. <br />
          Example: Shift start is 9am. Officer arrives at 9.06am. Their timesheet will be adjusted to 10am. 
          </div>
          <div style="margin: 8px 0">&bull; Up to 30 minutes late – timesheet is cut to 2 hours. <br />
          Example: Shift start at 10pm. Officers arrives at 10.24pm. Their timesheet will be adjusted to 12am. 
          </div>
          <div>&bull; Up to 1 hour late – further disciplinary actions (timesheet can be cut/disregarded, etc.).</div>
        <div style="font-weight: bold; margin: 15px 0 0 0;">BEING LATE OVER 3 TIMES IS A BASE OF REMOVAL FROM THE SCHEDULE AND/OR (IMMEDIATE) TERMINATION.</div>`,
        signed: false
      },
      {
        label: 'Cell phone use while on duty is strictly prohibited unless official Company business and/or emergency. No earphones (regular or wireless) are allowed to be used while being on post.',
        signed: false
      },
      {
        label: 'Smoking while on duty is prohibited.',
        signed: false
      },
      {
        label: 'It is a GammaTeam Security employee\'s responsibility to immediately inform the Office regarding address and/or cell phone changes.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall check his/her schedule through the Deputy Application every day. If after checking the schedule, and he/she determines they are not available for certain shifts, the Operations Manager and/or Site Supervisor shall be informed immediately. The review of the Deputy Application shall be performed not less than 12 hours before the shift starts. Failure to inform Company management may result in a warning notice and/or removal of an employee from the schedule.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall begin and end their shift by using his/her cell phone through the Deputy Application. During this procedure, a cell phone GPS shall be turned on. If he/she is unable to utilize the application due to Deputy App or cell phone issues, he/she shall inform an Operations Manager/Site Supervisor immediately. Failure to comply with the rule may result in removal from schedule.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall check his/her e-mail box every day for schedule updates, or any other information sent by Company management.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall always be properly uniformed at during each shift, i.e. shirt tucked in. His/ her badge shall be always visible, facial hair shall be trimmed, and long hair shall be up. No earrings and/or piercings  are permitted. No tattoos are permitted. All tattoos shall be covered while on duty.',
        signed: false
      },
      {
        label: `GammaTeam Security employee shall not leave the post until another GammaTeam Security employee arrives to relieve him. Failure to comply with the rule may result in removal from the schedule and/or discharge. 
        <div style="font-weight: bold; margin: 10px 0 0 0; text-transform: uppercase;">ABANDONING THE POST is the base for TERMINATION.</div>`,
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall carry his/her PERC card, FCC card (if applicable), State ID, Badge and Company ID at all times while on duty.',
        signed: false
      },
      {
        label: 'Uniform and fingerprints as well as PERC renewals and FCC card application expenses are paid by GammaTeam Security employees. If the Company pays for it, <span style="font-weight: bold;">the same amounts shall be later deducted from their paychecks</span>.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee shall complete <strong>Daily Reports</strong> and submit those to an Operations manager or Site Supervisor. Failure to comply with the rule may result in removal from the schedule and/or discharge.',
        signed: false
      },
      {
        label: 'GammaTeam Security employee who is late for his/her shift shall receive a warning notice from an Operations Manager or Site Supervisor. If he/she is continuously late, he may be removed from the schedule and/or discharged. Return to the schedule is at the discretion of Company management.',
        signed: false
      },
      {
        label: 'If GammaTeam Security employee does not report to his/her work assignment without informing an Operations Manager or Site Supervisor prior to the start of the shift, and another employee has been assigned to cover the shift, the Company shall withhold from the errant Officer any costs of lost shift hours including overtime hours.',
        signed: false
      },
      {
        label: 'If GammaTeam Security employee does not show up at work, the situation is defined as a NO CALL/NO SHOW, and he is subject to be immediately removed from the schedule, or depending on the circumstances, be terminated at once upon decision of the Company management. Deductions for the hours the Company lost because of such no call/no show shall be applied to the employee’s paycheck.',
        signed: false
      },
      {
        label: 'Damages caused by the actions of GammaTeam Security employee to the Company shall be deducted from GammaTeam Security employee’s check. Such damages include but not limited to: call offs, no call/no show, forced replacement of GammaTeam Security employee due to his behavior/actions, lateness, failure to return Company property (patches, IDs, etc).',
        signed: false
      },
      {
        label: 'If GammaTeam Security employee is requested by the Company to go for a drug test, and he is clear, the Company reimburses such expense. If he is not clear, that expense shall be on the officer and his employment with the Company is terminated. Refusal to do a drug test is grounds for immediate termination.',
        signed: false
      },
      {
        label: 'GammaTeam Security employees sign in and out of shift assignments utilizing their cellphone and the Deputy Application provided by the Company. The employees acknowledge the system generates a payroll timesheet which is approved by the Office. This information is utilized the Company’s official payroll Any abuse of the Deputy Application and/or payroll system shall result in immediate termination of the errant employee.',
        signed: false
      },
      {
        label: 'Request for a day off/time off shall be submitted to the Operations Manager/Site Supervisor/Office at last 14 days before the requested dates unless emergency.',
        signed: false
      },
      {
        label: 'If a GammaTeam Security employee calls off due to sickness, he/she shall provide a medical document reflecting his  medical condition. Failure to submit the document may result in removal from schedule or discharge.',
        signed: false
      },
      {
        label: 'GammaTeam Security employees are strongly advised not to remove his or her personal contact information from "Deputy". Incompliance with this policy may result in loss or fewer hours of work, and possibly being discarded. Nobody is able to view your personal information except for the management.',
        signed: false
      },
      {
        label: `The following outlines GammaTeam Security’s progressive discipline processes:
        <div style="margin: 15px 0 0 0;">
          <div style="margin: 8px 0 0 0">
            <strong>Verbal Warning</strong>: An Operations manager or Site Supervisor will verbally counsel GammaTeam Security employee about an issue of concern. A written record of the discussion will be placed in GammaTeam Security employee’s personnel file.
          </div>
          <div style="margin: 8px 0 0 0">
            <strong>Written Warning</strong>: Written warnings are used for such behaviors or violations that an Operations manager and or Site Supervisor determines to be serious or when a verbal warning does not suffice or improve unacceptable behavior. Written warnings are placed in GammaTeam Security employee’s personnel file.
          </div>
          <div style="margin: 8px 0 0 0">
            <strong>Suspension</strong>: A suspension is utilized when the employee has failed to respond to progressive discipline utilized with Verbal Warnings and Written Warnings or if the employee has engaged in behavior that warrants a suspension. A suspended employee may be terminated should an internal investigation determine termination is warranted. Termination decisions shall be made by Operations manager and the President of the Company
          </div>
        </div>`,
        signed: false
      },
      {
        label: 'If you submit your resignation without two weeks notice to GammaTeam Security Office, all the applicable damages GammaTeam Security bears for such resignation will be calculated and deducted from the final paycheck of such employee.',
        signed: false
      },
      {
        label: 'Resigning GammaTeam Security employees shall fill out a resignation form and forward it to the Office via e-mail or Operations Manager or Site supervisor. The employee shall provide two weeks’ notice in writing to facilitate transition out of the organization',
        signed: false
      },
      {
        label: 'Resigning GammaTeam Security employees shall return all Company property at the time of resignation or termination upon request from the Company, including badges, patches and identification cards. Failure to return some items shall result in deductions for the cost of these items from his/her final paycheck and/or final paycheck shall be held until such items are returned by the employee. Security Officers assigned to Unity school job site shall return Unity School property to the school Site Supervisor or Unity School Representative.',
        signed: false
      },
      {
        label: 'All questions and issues (payroll/schedule/work-related situations) shall be submitted in writing to info@gammateamcorp.com unless it is an emergency case.',
        signed: false
      },
      {
        label: 'An employee’s final paycheck shall be delivered by U.S. mail, to GammaTeam Security employee’s address listed in their personnel file. Exceptions apply if allowed by the Company President.',
        signed: false
      },
      {
        label: 'All visits to the Office shall be made by appointment through an Operations manager or the Executive Assistant.',
        signed: false
      },
      {
        label: 'If company provides transportation through the third party (Uber, Lyft, Taxi), all expenses will be reimbursed to the company via the deductions from paycheck.',
        signed: false
      },
      {
        label: 'If company provides transportation through the third party (Uber, Lyft, Taxi), all expenses will be reimbursed to the company via the deductions from paycheck.',
        signed: false
      },
      {
        label: `<div style="font-weight: bold;">DRUG-FREE WORKPLACE POLICY</div>
        <div style="margin: 15px 0 0 0">
          GammaTeam Security (the Company) intends to help provide a safe and drug- free work environment for our clients and our employees. With this goal in mind and because of the serious drug abuse problem in today’s workplace, we are establishing the following policy for existing and future employees of GammaTeam Security
        </div>
        <div style="text-decoration: underline; margin: 15px 0 0 0;">The Company explicitly prohibits:</div>
        <div style="margin: 8px 0 0 0;">
          The use, possession, solicitation for, or sale of narcotics or other illegal drugs, legal drugs such as cannabis, alcohol, or prescription medication without a prescription on Company or customer premises or while performing an assignment.
        </div>
        <div style="margin: 8px 0 0 0;">
          Being impaired or under the influence of legal or illegal drugs, cannabis or alcohol away from the Company or customer premises, if such impairment or influence adversely affects the employee’s work performance, the safety of the safety of the employee or of others or puts at risk the Company’s reputation.
        </div>
        <div style="margin: 8px 0 0 0;">
          Possession, use, solicitation for, or sale of legal or illegal drugs or alcohol away from the Company or customer premises, if such activity or involvement adversely affects the employee’s work performance, the safety of the employee or other, or puts at risk the Company’s reputation.
        </div>
        <div style="margin: 8px 0 0 0;">
          The presence of any detectable amount of prohibited substances in the employee’s system while at work, while on the premises of the Company or it’s customers, or while on company business. “Prohibited substances” include illegal drugs, cannabis, alcohol, or prescription drugs not taken in accordance with a prescription given to the employee.
        </div>
        <div style="text-decoration: underline; margin: 15px 0 15px 0">The Company will conduct drug and/or alcohol testing under any of the following circumstances:</div>
        <div style="margin: 8px 0 0 0;">
          <strong>RANDOM TESTING</strong>: Employees may be selected at random for drug and/alcohol testing at any interval determined by the Company. 
        </div>
        <div style="margin: 8px 0 0 0;">
          <strong>FOR- CAUSE TESTING</strong>: The Company may ask an employee to submit to a drug and/or alcohol test at any time it feels that the employee may be under the influence: of drug or alcohol, including, but not limited to, the following circumstances: evidence of drugs or alcohol on or about the employee’s person or in the employee’svicinity, unusual conduct on the employee’s part that suggests impairment or influence of drugs or alcohol, negative performance patterns, or excessive and unexplained absenteeism or tardiness. 
        </div>
        <div style="margin: 8px 0 0 0;">
          <strong>POST- ACCIDENT TESTING</strong>: Any employee involved in an on-the-job accident or injury under circumstancesthat suggest possible use or influence of drugs or alcohol in the in the accident or injury event may be asked to submit to a drug and/or alcohol test. “Involved in an on-the- job accident or injury” means not only the one who was or could have been injured, but also any employee who potentially contributed to the accident or injury event in aby way.
        </div>
        <div style="margin: 15px 0 0 0">If an employee is tested for drugs or alcohol outside of the employment context and the results indicate a violation of this policy, or if an employee refuses a request to submit to testing under this policy, the employee may be subject to appropriate disciplinary action, up to and possibly including discharge from employment. In such a case, the employee will be given an opportunity to explain the circumstances prior to any final employment action becoming effective.</div>`,
        signed: false
      }
    ],
    policy_idx: 1,
    error_message: '',
    code: '',
    onboarding: {},
    application: {},
    found_onboarding: false,
    redirect_time: 10,
    preliminary_questions: [
      {
        label: 'What type of position are you looking for - full-time, part-time, seasonal, etc.',
        value: '',
        disclaimer: 'Full-time and part-time positions are available but not guaranteed',
        type: 'text'
      },
      {
        label: 'Will you accept part-time work until full-time work is available?',
        value: '',
        disclaimer: 'Full-time work is not guaranteed',
        type: 'boolean'
      },
      {
        label: 'Are you willing to do background check and drug test?',
        value: '',
        type: 'boolean',
        disclaimer: 'This is mandatory for all employees. The company reserves the right to not hire candidates who refuse/do not pass background check and/or drug test. All background checks and drug test are paid by candidates.'
      },
      {
        label: 'Can you stand, sit and walk for long periods of time?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Assignments at a school require long walks up and down the stairs. Can you handle that on a daily basis?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Security guard position requires to be in good physical & mental state of health to secure and protect the assigned areas/property/clients. If you have some restrictions, please, specify:',
        value: '',
        type: 'text',
        disclaimer: 'Mental disorder (bipolar, etc.), medications taken, pregnancy (how far along in term), ongoing rehab (how many months)?'
      },
      {
        label: 'Can you work weekends and holidays?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Do you have a reliable transportation?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Will you accept flexible schedule/schedule that could change every week?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Lowest amount of hours per week which you will accept, if hired?',
        value: '',
        type: 'text',
        disclaimer: null
      },
      {
        label: 'Are you customer-oriented?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Do you have any experience in working at a school as security before?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Can you only work at sites on the public transportation (bus/trolley) line?',
        value: '',
        type: 'boolean',
        disclaimer: null
      },
      {
        label: 'Do you have a DL and personal vehicle?',
        value: '',
        type: 'boolean',
        disclaimer: null
      }
    ],
    availability: [
      {
        key: 'monday',
        from: 'off',
        to: 'off'
      },
      {
        key: 'tuesday',
        from: 'off',
        to: 'off'
      },
      {
        key: 'wednesday',
        from: 'off',
        to: 'off'
      },
      {
        key: 'thursday',
        from: 'off',
        to: 'off'
      },
      {
        key: 'friday',
        from: 'off',
        to: 'off'
      },
      {
        key: 'saturday',
        from: 'off',
        to: 'off'
      },
      {
        key: 'sunday',
        from: 'off',
        to: 'off'
      }
    ],
    required_documents: [
      {
        key: 'drivers_license',
        label: 'Driver\'s License'
      },
      {
        key: 'foid_card',
        label: 'FOID Card'
      }
    ],
    files: [],
    hours: []
  }),
  methods: {
    is_valid_hours: function (hours) {
      const matches = hours.match(/(\d+):(\d+)(am|pm)/i)
      return matches
    },
    get_hours_per_day: function (day) {
      if (day.from.trim().toLowerCase() === 'off' && day.to.trim().toLowerCase() === 'off') return 0
      else {
        const _from = this.is_valid_hours(day.from)
        const _to = this.is_valid_hours(day.to)
        if (_from && _to) {
          _from[1] = parseInt(_from[1])
          _from[2] = parseInt(_from[2])
          _to[1] = parseInt(_to[1])
          _to[2] = parseInt(_to[2])
          if (_from[3].toUpperCase() === 'PM' && _from[1] !== 12) _from[1] = _from[1] + 12
          if (_to[3].toUpperCase() === 'PM' && _to[1] !== 12) _to[1] = _to[1] + 12
          if (_from[3].toUpperCase() === 'AM' && _from[1] === 12) _from[1] = _from[1] - 12
          if (_to[3].toUpperCase() === 'AM' && _to[1] === 12) _to[1] = _to[1] - 12

          const _hours = _to[1] - _from[1]
          const _totalHours = _hours - (_hours > 6 ? (30 / 60).toFixed(2) : 0)
          if (_totalHours > 0) {
            day.total = _totalHours
            return _totalHours
          } else {
            day.total = 0
            return 'N/A'
          }
        }
      }
    },
    validate_hours: function (day) {
      const _from = $('#' + day.key + '_from')
      const _to = $('#' + day.key + '_to')

      // validate from hours
      if (day.from.toLowerCase() !== 'off') {
        if (!this.is_valid_hours(day.from)) _from.addClass('day_error')
        else _from.removeClass('day_error')
      } else _from.removeClass('day_error')

      // validate to hours
      if (day.to.toLowerCase() !== 'off') {
        if (!this.is_valid_hours(day.to)) _to.addClass('day_error')
        else _to.removeClass('day_error')
      } else _to.removeClass('day_error')
    },
    onboarding_next: function () {
      switch (this.onboarding.step) {
        case 1:
          if (this.all_signed()) {
            this.submitting = true
            this.onboarding.step++
            this.requestHandler.doPut('application/onboarding/', '', {
              id: this.onboarding.id,
              complete: false,
              data: this.policies_rules,
              step: this.onboarding.step
            })
              .then(response => {
                console.log(response)
                this.policy_idx = 1
                this.submitting = false
              })
          }
          break
        case 2:
          if (this.all_preliminary_filled()) {
            this.submitting = true
            this.onboarding.step++
            this.requestHandler.doPut('application/onboarding/', '', {
              id: this.onboarding.id,
              complete: false,
              preliminary_questions: this.preliminary_questions,
              step: this.onboarding.step
            })
              .then(response => {
                console.log(response)
                this.submitting = false
              })
          }
          break
        case 3:
          if (this.get_total_weekly_hours() > 0) {
            this.submitting = true
            this.onboarding.step++
            this.requestHandler.doPut('application/onboarding/', '', {
              id: this.onboarding.id,
              complete: false,
              availability: this.availability,
              step: this.onboarding.step
            })
              .then(response => {
                console.log(response)
                this.submitting = false
              })
          }
          break
        case 4:
          if (this.files.length > 0) {
            this.submitting = true
            // this.onboarding.step++
            this.upload_files()
              .then(response => {
                this.submitting = false
                if (response.data.success) {
                  this.onboarding.step++
                  this.onboarding.complete = true
                  this.complete_onboarding()
                } else {
                  if (typeof response.data.errors[0] !== 'undefined') {
                    this.error_message = response.data.errors[0]
                  }
                }
              })
          }
          break
      }
    },
    advance_policy: function () {
      this.policies_rules[this.policy_idx - 1].signed = true
      if (this.policy_idx < this.policies_rules.length) this.policy_idx++
      else this.onboarding_next()
    },
    advance_preliminary: function () {
      const item = this.preliminary_questions[this.policy_idx - 1]
      if (item.value) {
        this.error_message = ''
        if (this.policy_idx < this.preliminary_questions.length) this.policy_idx++
        else this.onboarding_next()
      } else this.error_message = 'Please answer the current question to your best ability'
    },
    advance_hours: function () {
      if (this.get_total_weekly_hours() > 0) {
        this.error_message = ''
        this.onboarding_next()
      } else {
        this.error_message = 'Please enter at least 1 day of your availability'
      }
    },
    complete_onboarding: function () {
      this.onboarding.complete = true

      this.requestHandler.doPut('application/onboarding/', '', {
        id: this.onboarding.id,
        complete: this.onboarding.complete,
        step: this.onboarding.step
      })
        .then(response => {
          console.log(response)
        })
    },
    all_signed: function () {
      const signed = this.policies_rules.filter(item => {
        return item.signed === true
      })
      if (signed.length === this.policies_rules.length) return true
      else return false
    },
    all_preliminary_filled: function () {
      let _preliminarySigned = true
      this.preliminary_questions.forEach(item => {
        if (!item.value) {
          _preliminarySigned = false
          return _preliminarySigned
        }
      })
      return _preliminarySigned
    },
    get_total_weekly_hours: function () {
      let _totalHours = 0
      this.availability.forEach(day => {
        const _hours = this.get_hours_per_day(day)
        if (!isNaN(_hours)) _totalHours += this.get_hours_per_day(day)
      })
      return _totalHours
    },
    prep_file_for_upload: function (item, e) {
      const file = e.target.files[0]
      const src = URL.createObjectURL(file)
      let valid = false
      const img = $('#' + item.key + '_preview')

      if (file.name.toLowerCase().match(/(.jpg|.jpeg|.gif|.png|.tiff)/)) {
        img.attr('src', src)
        img.css('visibility', 'visible')
        valid = true
      } else if (file.name.toLowerCase().match(/(.pdf|.doc|.docx)/)) valid = true

      if (valid) {
        this.error_message = ''
        this.files.push({
          key: item.key,
          label: item.label,
          file: file
        })
      } else {
        this.error_message = 'Invalid document file type selected'
        img.attr('src', '')
        img.css('visibility', 'hidden')
        this.files.splice(item.key, 1)
        this.$refs[`documentUpload_${item.key}`].value = null
      }
      console.log(this.files)
    },
    upload_files: function () {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const progress = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
          console.log(progress)
        }
      }
      // form data
      const formData = new FormData()
      this.files.forEach(item => {
        formData.append('files[' + item.key + ']', item.file)
      })
      console.log(formData)

      // send upload request
      return this.axios.post(
        '/application/onboarding/file-upload/' + this.code,
        formData,
        config
      )
    },
    init: function () {
      this.hours = []
      for (let i = 0; i < 24; i++) {
        if (i === 0) {
          this.hours.push({ label: '12:00AM', id: i })
          // this.hours.push({ label: '12:30AM', id: i })
        } else if (i === 12) {
          this.hours.push({ label: '12:00PM', id: i })
          // this.hours.push({ label: `${i}:30AM`, id: i })
        } else if (i < 12) {
          this.hours.push({ label: `${i}:00AM`, id: i })
          // this.hours.push({ label: `${i}:30AM`, id: i })
        } else {
          this.hours.push({ label: `${(i - 12)}:00PM`, id: i })
          // this.hours.push({ label: `${(i - 12)}:30PM`, id: i })
        }
      }
      console.log(this.hours)
      let redirectInterval
      this.requestHandler.doGet('application/onboarding/', this.code)
        .then(response => {
          if (response.data.success) {
            this.found_onboarding = true
            if (response.data.onboarding.data) this.started = true
            this.application = response.data.application
            this.onboarding = response.data.onboarding
            if (this.onboarding.data) {
              this.policies_rules = JSON.parse(this.onboarding.data)
            }
          } else {
            this.found_onboarding = false
            redirectInterval = setInterval(() => {
              this.redirect_time--
              if (this.redirect_time === 0) {
                clearInterval(redirectInterval)
                this.goToPage('Home')
              }
            }, 1000)
          }
        })
    }
  },
  computed: {
    policy_label_computed: function () {
      return this.policies_rules[this.policy_idx - 1].label
    },
    preliminary_questions_label_computed: function () {
      return this.preliminary_questions[this.policy_idx - 1].label
    },
    preliminary_questions_disclaimer_computed: function () {
      return `* ${this.preliminary_questions[this.policy_idx - 1].disclaimer}`
    },
    return_url_computed: function () {
      return document.location.href
    },
    added_files_computed: function () {
      let _markup = ''
      if (this.files.length > 0) {
        _markup = '<div style="font-weight: 600; display: block; background-color: #cdcdcd; padding: 10px;">The following files will be uploaded:</div>'
        this.files.forEach(item => {
          _markup += '<span style="font-weight: 600; margin: 6px 0 0 0; display: inline-block;">' + item.label + ':</span> ' + item.file.name + '<br />'
        })
        return _markup
      } else return 'No files selected for upload'
    },
    total_hours_computed: function () {
      return this.get_total_weekly_hours()
    },
    position_title_computed: function () {
      if (typeof this.application.position === 'undefined') return ''
      return this.application.position.position_title
    },
    all_preliminary_filled_computed: function () {
      return this.all_preliminary_filled()
    },
    all_signed_computed: function () {
      let _preliminarySigned = true
      this.preliminary_questions.forEach(item => {
        if (!item.value) {
          _preliminarySigned = false
          return _preliminarySigned
        }
      })
      return this.all_signed()
    },
    is_complete: function () {
      return this.onboarding.complete
    },
    found_onboarding_computed: function () {
      return this.found_onboarding
    },
    redirect_time_computed: function () {
      return this.redirect_time
    }
  },
  mounted: function () {
    if (typeof this.$router.currentRoute.params.code !== 'undefined') {
      this.code = this.$router.currentRoute.params.code
      this.init()
    }
  }
}
</script>

<style scoped>
header {
  width: 100%;
  height: auto;
  background: rgba(100,100,100,1);
  background: linear-gradient(90deg, #2a7afa 0%, transparent 100%);
  padding: 10px 0;
  box-shadow: 10px 0 25px #000;
}
header div {
  position: relative;
}
header h1 {
  color: rgba(0,0,0,.5);
  letter-spacing: 0px;
  text-transform: capitalize;
  padding: 20px 0 20px 120px;
  background-image: url(/images/logo.png);
  background-repeat: no-repeat;
  background-size: auto 80px;
  background-position: 20px 0;
}
header span.user_details {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(0,0,0,.75);
}
#content {
  color: black;
  padding: 15px 15px 0 15px;
  width: 100%;
}
.intro {
  max-width: 70%;
  margin: 15px 0 0 0;
}
h3, h4, h5 {
    letter-spacing: 0;
    text-transform: none;
}
p {
    letter-spacing: 0;
}
button {
    background-color: #2a7afa;
    border-radius: 10px;
    color: #fff;
    font-size: 2em;
}
.download {
  text-decoration: none;
}
.begin_onboarding_button {
    margin: 20px 0 0 0;
}
.submit_onboarding {
    margin: 20px 0 0 0;
    text-align: center;
}
.btn_next {
    margin: 0px 0 0 0;
    text-align: center;
}
.return_url {
  font-weight: 600;
  color: #2a7afa;
  word-break: break-all;
}
#content div.policies {
    padding: 10px 0;
    margin: 10px 0 0 0;
}
#content div.preliminary {
    padding: 10px 0;
    margin: 10px 0 0 0;
}
#content div.policy {
    margin: 10px 0px 20px 0;
}
#content div.policy input[type='checkbox'] {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: top;
    margin: 2px 0 0 0;
}
#content div.policy div.policy_step {
    display: block;
    width: 100%;
    font-weight: bold;
    font-family: 'Source Sans Pro';
    border-bottom: dashed 1px #999;
    padding: 5px 0 15px 0;
    text-align: left;
}
#content div.policy div.policy_label {
    display: block;
    width: 100%;
    font-size: 1em;
    line-height: 1.3em;
    margin: 10px 0 0 0;
}
.bold {
  font-weight: bold;
}
#content div.policy div.policy_action {
    display: block;
    width: 100%;
    text-align: center;
}
.preliminary input[type=text] {
  width: 100%;
  border: solid 1px #666;
  padding: 6px 8px;
  border-radius: 4px;
  margin: 8px 0 0 0;
}
.preliminary .disclaimer {
  font-style: italic;
  font-family: 'Source Sans Pro';
  letter-spacing: .5px;
}
.availibility_table {
  margin: 10px 0 0 0;
}
.availibility_table thead tr th {
  font-weight: bold;
  background-color: #cdcdcd;
  padding: 6px;
}
.availibility_table tbody tr td {
  padding: 6px;
}
.availibility_table tbody tr td:first-child {
  background-color: #cdcdcd;
}
.availibility_table input[type=text] {
  text-transform: uppercase;
}
.availibility_table select {
  border: solid 1px #666;
  width: 100px;
  padding: 6px;
  border-radius: 4px;
}
/* DOCUMENTS */
#content div.documents {
    margin: 10px 0 0 0;
}
.documents label {
  text-transform: capitalize;
  margin: 15px 0 0 0;
  display: inline-block;
  width: 200px;
  font-weight: 600;
}
#content .documents div.policy div.policy_label {
  margin: 0;
}
#content .documents div.selected_files {
  margin: 10px 0 0 0;
}
.documents .file_upload_preview {
  max-height: 200px;
  max-width: 340px;
  display: block;
  margin: 10px 0 0 0;
  visibility: hidden;
}
#pending_documents_list {
  font-weight: 600;
}
div.total_hours {
  font-weight: 600;
  margin: 10px 0 0 0;
  text-transform: uppercase;
}
.day_error {
  background-color: rgba(255,125,125,1);
  color: white;
  border: solid 3px red;
}
.error_message {
  color: red;
  margin: 10px 0 0 0;
  font-weight: 600;
}
@media screen and (max-width: 480px){
  header {
    height: 80px;
  }
  header h1 {
    background-size: auto 60px;
    padding: 15px 10px 0 75px;
    font-size: 1.1em;
    background-position: 5px 0;
    text-align: right;
    height: 60px;
    margin: 0 10px 0 0;
    display: inline-block;
  }
  .intro {
    max-width: 100%;
  }
  .availibility_table select {
    border: solid 1px #666;
    width: 70px;
    padding: 6px;
    border-radius: 4px;
  }
}
</style>
