import store from '@/store'

const requestHandler = class requestHandler {
  constructor () {
    this.axios = window.axios
  }

  /**
     * Common function to perform GET requests for the API
     * @param prefix
     * @param command
     * @returns {Promise<AxiosResponse<any>>}
     */
  doGet (prefix, command) {
    return this.axios.get(prefix + command)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        console.error(error)
        if (error.toString().indexOf('Request failed with status code 401')) {
          store.commit('logoutUser')
        }
        return Promise.reject(error)
      })
  }

  /**
     * Common function to perform SET requests to the API
     * @param command
     * @param data
     */
  doPost (prefix, command, data) {
    return this.axios.post(prefix + command, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        console.error(error)
        if (error.indexOf('Request failed with status code 401')) {
          store.commit('logoutUser')
        }
        return Promise.reject(error)
      })
  }

  /**
     * Common function to perform SET requests to the API
     * @param command
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
  doPut (prefix, command, data) {
    return this.axios.put(prefix + command, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        console.error(error)
        if (error.indexOf('Request failed with status code 401')) {
          store.commit('logoutUser')
        }
        return Promise.reject(error)
      })
  }

  /**
     * Common function to delete data on server
     * @param prefix
     * @param command
     * @param data
     * @returns {Promise<unknown>}
     */
  doDelete (prefix, command) {
    return this.axios.delete(prefix + command)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        console.error(error)
        if (error.indexOf('Request failed with status code 401')) {
          store.commit('logoutUser')
        }
        return Promise.reject(error)
      })
  }
}

export default requestHandler
